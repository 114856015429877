
export function isDefined(arg) {
    if (typeof arg === 'undefined' || arg === 'undefined' || arg === null) {
        return false;
    }
    return true;
}

export function isStringEmpty(arg) {
    return arg === '';
}

export function isArrayEmpty(arg) {
    return arg.length === 0;
}

export function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
}