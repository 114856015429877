import styled from "styled-components";
import * as palette from "../../styles/variables";

export const ProfileContainer = styled.div`
* {
    font-family: ${palette.FONT_FAMILY} !important;
}

* {
  padding: 0;
  margin: 0;
}


#doctorProfile {
  background: white;

}


#doctorProfile #nav_main_div2 {
  background: white;
  box-shadow: 0px 4px 10px rgba(10, 48, 61, 0.06);
  top: 0;
}

#doctorProfile #nav_main_div{
  background: white;
  box-shadow: 0px 4px 10px rgba(10, 48, 61, 0.06);
}

#doctorProfile #doctorprofile_container_main{
  position: relative;
  padding-bottom:5%;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.mt-5 {
  margin-top: 3rem!important;
  
  box-sizing: border-box;
}
.p-2 {
  padding: 0.5rem!important;
}

img, svg {
  vertical-align: middle;
}
img {
  border-style: none;
}
img {
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.65rem;
}
.card {

  word-wrap: break-word;
}
.nav-link:hover{
  background: #007bff;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
}
nav-link:active {
  background: #007bff;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
}


.nav-link {
  margin-bottom: -1px;
  border: 3px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.mb-1, .my-1 {
  margin-bottom: 0.25rem!important;
}
.card-title {
  margin-bottom: 0.75rem;
  font-size:30px;
}

.h5, h5 {
  font-size: 1.25rem;
  font-weight: 700!important;
}
.text-info {
  color: #17a2b8!important;
  font-weight: 700!important;
   font-size:15px;
}
hHnZEY .h5, .hHnZEY h5 {
  font-size: 1.75rem !important;
  font-weight: 700!important;
}
.w-25 {
  width: 25%!important;
}
label {
  display: inline-block;
  
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 0.25rem;
  margin-bottom:15px;
}
.pt-0, .py-0 {
  padding-top: 12px !important;
}
.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 1.25rem;
}
.mb-4, .my-4 {
  margin-bottom: 3.5rem!important;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.tab-content>.active {
  display: block;
}
.fade {
  transition: opacity .15s linear;
}
.tab-content>.active {
  display: block;
}
.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}
p {
  margin-top: 0;
  margin-bottom: 1rem;
}
.font-weight-bold {
  font-weight: 700!important;
}
h3 {
  font-size: 1.75rem;
}
.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}
.btn-primary {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.text-center {
  text-align: center!important;
  align-self: center;
}
.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}
.nav-justified .nav-item, .nav-justified>.nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-link{
  font-size: 20px;
}
.btn {
  display: inline-block;
  font-weight: 400;
 
  text-align: center;
  vertical-align: middle;

  
  user-select: none;
 
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}



@media (max-width: 1202px) {
  #doctorProfile .nav-co {
    padding: 3vw 1vw 3vw 1vw;
  }
}

@media (min-width: 992px){
.container, .container-lg, .container-md, .container-sm {
    max-width: 960px;
}
.col-lg-4 {
  flex: 0 0 33.333333%;
  max-width: 33.333333%;
}
.col-lg-8 {
  flex: 0 0 66.666667%;
  max-width: 66.666667%;
  padding-right: 15px;
    padding-left: 15px;
}



}
@media (min-width: 576px){
.container, .container-sm {
    max-width: 540px;
}}

@media (min-width: 768px){
.container, .container-md, .container-sm {
    max-width: 975px;
}}

`